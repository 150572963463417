:root {
    /*Colors*/

    --dark-grey: rgba(0, 0, 0, 0.728); /*Color used in Navbar for font color*/
    --brainacs-orange: #FFA500;
    --snow-white: #f8f8ff;
    --white: #fafafa;

    /*Fonts*/
    --braiancs-font: 'Open Sans', sans-serif;
    --desktop-schrift-h1: 36px;
    --desktop-schrift-h2: 24px;
    --desktop-schrift-p: 18px;

    --tablet-schrift-h1: 30px;
    --tablet-schrift-h2: 20px;
    --tablet-schrift-p: 16px;

    --mobil-schrift-title: 24px;
    --mobil-schrift-p: 14px;
}

/* @import '../../styles/variables.css'; -> immer importieren in den anderen 
CSS-Dateien bei der Verwendung dieser globalen Variablen */