@import '../../styles/variables.css';

.btn {
    margin-top: 5px;
    border: 1px solid #1c1d1f;
    padding: 8px 20px;
    border-radius: 0px;
    width: 180px;
    height: 40px;
    border-radius: 20px;
    background-color: var(--brainacs-orange);
    border-bottom: 1px solid var(--brainacs-orange);
    border-left: 1px solid var(--brainacs-orange);
    border-right: 1px solid var(--brainacs-orange);
    border-top: 1px solid var(--brainacs-orange);
    outline: none;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    color: var(--snow-white);
}

.btn:hover{
    /*transition: all 0.3s ease out; */
	border-bottom: 2px solid var(--brainacs-orange);
    border-left: 2px solid var(--brainacs-orange);
    border-right: 2px solid var(--brainacs-orange);
    border-top: 2px solid var(--brainacs-orange);
	background-color: white;
	color: var(--brainacs-orange);
}

.contact-us-icon {
	display: none;
}

@media screen and (max-width: 450px) {
	.btn {
		display: none;
	}

	.btn:hover{
		display: none;
	}

	.contact-us-icon {
		position: relative;
		top: -3px;
		left: 90px;
		width: 40px; /* Die Breite und Höhe des Kreises anpassen */
		height: 40px;
		background-color: white;
		border-radius: 50%; /* Rundes Element erstellen */
		color: var(--brainacs-orange);
		display: flex;
		margin-right: 5px;
		justify-content: center;
		align-items: center;
		opacity: 0.8;
		padding-right: 5px;
	}

}


