@import '../../styles/variables.css';


@media screen and (max-width: 3096px) {
	
	.logo-width{
	width: 160px;
	}

	.navbar-container {
	height: 90px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 15px;
	top: 0;
	font-size: 1.2rem;
	font-size: 1.2rem;
	z-index: 999;
	/*border-bottom: 1px darkgray solid; */
	box-shadow: 0 -6px 10px 5px rgba(0,0,0,0.5);
	overflow: hidden;

	}
	.button-margin {
	margin-left: 20px;
	}
	.navbar-logo {
	color: var(--snow-white);
	justify-self: start;
	cursor: pointer;
	margin-right: 20px;
	margin-top: 15px;

	}

	.nav-menu {
	display: flex;
	gap: 15px; /* Abstand zwischen den Labels */
	margin-right: 100px; /* Abstand der Labels zum Logo */
	}

	.nav-item {
	display: flex;
	align-items: center;
	height: 80px;
	margin-left: 20px;
	}


	.nav-links {
	color: var(--dark-grey);
	text-decoration: none;
	font-weight: 500;
	padding: 0.5rem 1rem;
	margin:auto;
	}

	.nav-links:hover {
	color: var(--brainacs-orange);
	border-bottom: 1px solid var(--brainacs-orange);
	border-radius: 0px;
	transition: all 0.2s ease-out;
	}

	.fa-bars {
	color: black;
	align-items: end;
	}

	.menu-icon {
	display: none;
	}
}



@media screen and (max-width: 450px) {
	img{
		width: 150px;
		}
	
		.navbar-container {
		height: 90px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 15px;
		top: 0;
		font-size: 1.2rem;
		font-size: 1.2rem;
		z-index: 999;
		/*border-bottom: 1px darkgray solid; */
		box-shadow: 0 -6px 10px 5px rgba(0,0,0,0.5);
		overflow: hidden;
	
		}
		.button-margin { 
		display: flex;
		margin-left: 20px;
		padding-right: 5px;
		}
		.logo-width{
			width: 140px;
			height: 140px;
		}
		.navbar-logo {
		color: var(--snow-white);
		justify-self: start;
		cursor: pointer;
		margin-left: -20px;
		margin-top: -100px;
		}
		i { margin-right: 10px}
		.nav-menu {
		display: flex;
		gap: 15px; /* Abstand zwischen den Labels */
		margin-right: 250px; /* Abstand der Labels zum Logo */
		}
	
		.nav-item {
		display: flex;
		align-items: center;
		height: 80px;
		margin-left: 20px;
		overflow: hidden;
		}
	
	
		.nav-links {
		color: var(--dark-grey);
		text-decoration: none;
		padding: 0.5rem 1rem;
		margin:auto;
		}
	
	
		.fa-bars {
		margin-left: 5px;
		color: var(--dark-grey);
		align-items: end;
		}
	
		.fa-times {
			color: var(--dark-grey);
			align-items: end;
		}
	
		.menu-icon {
		display: none;
		}

		.icon-headset{
			position: relative;
			margin-right: 5px;
		}

}










@media screen and (max-width: 960px) {
.NavbarItems {
	position: relative;
}

.nav-item {

	width: 90%;
	padding-right: 2px;
	overflow: hidden;
}

.nav-menu {
	display:block;
	margin: auto;
	flex-direction: column;
	width: 100%;
	position: absolute;
	top: 80px;
	left: -100%;
	opacity: 1;
	transition: all 0.5s ease;
	overflow: hidden;
}


.nav-links {
	text-align: center;
	padding: 15px;
	width: 100%;
	display: table;
	margin:auto;
	font-size: 24px;
	font-weight: 500;
	text-decoration: 1px underline;
	color: rgb(100, 100, 100);
	overflow: hidden;
}

.navbar-logo {
	position: absolute;
	top: 0;
	left: 0;
	transform: translate(25%, 50%);
}

.menu-icon {
	display: block;
	position: absolute;
	width: 30px;
	top: 0;
	right: 0;
	transform: translate(-100%, 60%);
	font-size: 1.8rem;
	cursor: pointer;
}
.nav-menu.active {
	background-image: linear-gradient(to bottom, #FFA500, #FFF); 
	left: 0;
	opacity: 1;
	height: 4440px;
	transition: all 0.5s ease;
	z-index: 999;
	overflow: hidden;
	}
	
.fa-times {
	color: var(--dark-grey);
	font-size: 2rem;
}
.button-margin { 
	padding-left: 130px;
	text-align:center;
}

button {
 /*display:none --> fals button entfernt werden soll*/
}
}
