@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;1,400&family=PT+Sans:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;1,400&family=PT+Sans:wght@700&family=Poppins&display=swap');
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
}

.loader {
  width: 100%;
  height: auto;
  text-align: center;
  margin: 250px 0;
}

.loader ul {
  padding: 0;
  margin: 0;
}

.loader ul li {
  list-style: none;
  display: inline-block;
  margin: 0 2px;
  width: 60px;
  height: 60px;
  background-color: var(--brainacs-orange);
  border-radius: 50%;
  animation-name: bounce;
  animation-timing-function: ease-in-out;
  animation-duration: 1.4s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
}

.loader ul li:nth-child(1) {
 animation-delay: -0.32s;
}

.loader ul li:nth-child(2) {
  animation-delay: -0.16s;
}

@keyframes bounce 
{
  0%, 80%, 100%
  {
    transform: scale(0);
  }
  40%
  {
    transform: scale(1.0);
  }
}

.containerCookieClass {
  height: 100px;
  width: 100px;
}

.buttonCookie{
  height: 40px;
}
.buttonCookieWrapper{
  margin-top: 10px;
}
.declineButtonCookie {
  height: 40px;
}

.link-cookies {
  color: var(--brainacs-orange);
  text-decoration: underline;
}


@media screen and (max-width: 500px) {
  .containerCookieClass {
    height: 200px;
    width: 100px;
  }

  .buttonCookie{
    height: 40px;
  }
  .buttonCookieWrapper{
    
  }
  .declineButtonCookie {
    height: 40px;
  }

}