@import '../../styles/variables.css';

.dropdown-menu{
    width:550px;
    position: absolute;
    top: 85px;
    list-style: none;
    text-align: start;
    background-color: var(--snow-white);
    border-bottom: 2px solid var(--dark-grey);
    box-shadow: 0 -1px 1px 1px rgba(0,0,0,0.5);
}
a {
    background-color: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
    text-decoration: none;

}
.dropdown-menu li{
    color: var(--white);
}
td {
    padding-right: 20px;
}
.dropdown-title {
    height: 20px;
    padding-left: 30px;
    font-size: 14px;
    text-decoration: underline;
    font-weight: 800;
    color: rgb(0, 0, 0);

}
.row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    margin: 5px;
  }
  
  .row {
    display: flex;
    justify-content: space-between;
  }
  
  .column {
    flex: 1;
    padding: 10px;
    margin: 5px;
  }

  a {
    font-weight: bold;
  }
  
  .column div {
    text-align: center;
    width: 250px;
  }
  
  .column a {
    text-decoration: none;
    color: #333;
    padding: 5px;
    font-weight: bold;
  }
  
  .column a:hover {
    color: blue;
  }

.dropdown-menu li:hover{
    background-color: rgb(237, 237, 237);
}
.dropdown-menu.clicked {
    display: none;
  }
.dropdown_link{
    display: block;
    width: 200px;
    height: 100%;
    text-decoration: none;
    color: var(--dark-grey);
    padding:12px;
}

@media screen and (max-width: 960px) {
    .dropdown-menu{

        width:100%;
        text-decoration: none;
        position: absolute;
        top: 160px;
        height: 100%;
        list-style: none;
        text-align: start;
        background-color: black;
    }
button {
    height: 70px;
}

.dropdown-menu li{
    color: var(--white);
}

.dropdown-menu li:hover{
    background-color: var(--white);
}
.dropdown-menu.clicked {
    display: none;
  }
.dropdown_link{
    margin-left: 43%;
    display: table;
    width: 200px;
    height: 100%;
    text-decoration: none;
    color: var(--white);
    padding:12px;
}

}