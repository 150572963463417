.wartungsseite {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #ffcc84;
    border-bottom-right-radius: 0%;
    margin-bottom: 10px;
    padding-top: 30px;
    padding-left: 20px;
    padding-right: 20px;
}
.w-bg {
    height: 800px;
    background-color: #fed49a;
}

.wartungsseite-bg-top {
    height: 100px;
    width: 100%;
    background-color: #fed49a;
}

.wartungsImg {
    width: 300px;
    height: 300px;
}

.wartung-sub-title {
    font-size: 14px;
    font-weight: 400;
    color: var(--dark-grey);
    text-align: center;
    width: 90%;
    margin-bottom: 30px;

}

.wartung-title {
    font-size: 36px;
    font-weight: 500;
    margin-bottom: 10px;
    color: var(--dark-grey);
}
@media screen and (max-width: 685px) {
.wartungsseite-bg-top {
    height: 100px;
    width: 100%;
    background-color: #fed49a;

}

.wartungsseite-bg-bottom {
    height: 100px;
    width: 100%;
    background-color: #fed49a;

}

.wartungsseite {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #ffcc84;
    border-bottom-right-radius: 0%;
 }
 
 .wartungsImg {
     width: 300px;
     height: 300px;
 }
 
 .wartung-sub-title {
     font-size: 11px;
     margin-bottom: 30px;
     text-align: center;
     color: var(--dark-grey);
     padding-left: 20px;
     padding-right: 20px;
     width: 100%;
 }
 
 .wartung-title {
     font-size: 32px;
     font-weight: 500;
     margin-bottom: 10px;
     padding-top: 30px;
     padding-left: 20px;
     padding-right: 20px;
     color: var(--dark-grey)
 }
}


@media screen and (max-width: 445px) {
    .wartung-sub-title {
        font-size: 11px;
        margin-bottom: 30px;
        text-align: left;
        color: var(--dark-grey);
        padding-left: 20px;
        padding-right: 20px;
    }
}